import format from 'date-fns/format';

export function numberToMoney(_number: number) {
  if (_number && +_number) {
    const number = +_number;
    return `${number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
  }
  if (+_number === 0) return `${_number}`;
  return '';
}

export function moneyToNumber(_money: any): any {
  let money = _money;
  if (money) {
    money = money.replace(/\./g, '');
    if (Number.isInteger(+money)) {
      return +money;
    }
  }
  return null;
}

export function isNumeric(value: string) {
  return /^\d+$/.test(value);
}

export function hiddenNumber(_number: string) {
  let phone = '';
  const number = _number + '';
  if (number) {
    for (let i = 0; i < number.length; i++) {
      if (i > 0 && i < number.length - 3) {
        phone += '*';
      } else {
        phone += number.charAt(i);
      }
    }
    return phone;
  }
  return '';
}

export function divisible100(value: string) {
  return parseInt(value) % 100000 === 0;
}

export function calDuration(startTime: string | number) {
  const endTime = +new Date();
  return Math.ceil((endTime - +startTime) / 1000);
}

// export function formatWithTimeZone(time: any) {
//   return moment.tz(time, 'Asia/Ho_Chi_Minh').format('HH:mm:ss DD/MM/YYYY');
// }

export function formatTime(time: number | Date) {
  return format(time, 'dd-MM-yyyy hh:mm:ss');
}

export function formatDay(time: number | Date) {
  return format(time, 'dd-MM-yyyy');
}
export function formatBirthday(time: any) {
  return format(time, 'yyyy-MM-dd');
}

// export function isMobile() {
//   let check = false;
//   (function(a) {
//     if (
//       /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
//         a
//       ) ||
//       /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
//         a.substr(0, 4)
//       )
//     )
//       check = true;
//   })(navigator.userAgent || navigator.vendor || window.opera);

//   const isSameDevice = getRequireMobileStatus();
//   // if mp5 of TRUE = getRequireMobileStatus => true; else false
//   if (isSameDevice) {
//     const encodedTrue = window.btoa('true');
//     if (isSameDevice !== encodedTrue) {
//       return false;
//     }
//   }

//   return check;
// }

export function getName(fullName: string) {
  if (!fullName) return null;
  const a = fullName.trim().lastIndexOf(' '); // last occurence of space
  return fullName.substring(a + 1);
}

export function handleErrAPI(err: {
  status: number;
  data: { errorCode: string | number };
}) {
  let keyMessage = '';
  let valMessage = "Something's Wrong";
  if (err?.status === 422) {
    if (err.data?.errorCode) {
      const errorCode = +err.data?.errorCode;
      if (Number.isInteger(errorCode)) {
        switch (errorCode) {
          case 42: {
            valMessage = 'Confirm password incorrect';
            keyMessage = 'confirmNewPass';
            break;
          }
          case 41: {
            valMessage = 'Current password incorrect';
            keyMessage = 'currentPass';
            break;
          }
          case 10: {
            valMessage = 'These credentials do not match our records.';
            keyMessage = 'phoneNumber';
            break;
          }
        }
      }
    }
  }
  return [keyMessage, valMessage];
}

// export function filters(arr: any[], searchKey: any) {
//   const resultFilters: any[] = [];
//   arr.forEach((item: any) => {
//     const objectInclude = isObjectInclude(item, searchKey);
//     if (objectInclude) {
//       resultFilters.push(objectInclude);
//     }
//   });
//   if (resultFilters && resultFilters.length > 0) {
//     return resultFilters;
//   }
//   return null;
// }

// function isTextInclude(text: unknown, _searchKey: { toString: () => string; }) {
//   if (_searchKey) {
//     const searchKey = _searchKey
//       .toString()
//       .trim()
//       .toLowerCase();
//     return !!(text && text.toString().includes(searchKey));
//   }
//   return false;
// }

// function isObjectInclude(item: { [s: string]: unknown; } | ArrayLike<unknown>, search: any) {
//   const values = Object.values(item);
//   if (values.length > 0) {
//     for (let i = 0; i < values.length; i += 1) {
//       let value = values[i];
//       if (value) {
//         value = value
//           .toString()
//           .trim()
//           .toLowerCase();
//         if (isTextInclude(value, search)) {
//           return item;
//         }
//       }
//     }
//   }
//   return null;
// }
export const replaceCharacterLatin = (strSearch: string) => {
  return strSearch.replace(/./g, function (char: string) {
    switch (char.toLowerCase()) {
      case 'á':
      case 'ả':
      case 'à':
      case 'ạ':
      case 'ã':
      case 'â':
      case 'ấ':
      case 'ầ':
      case 'ẩ':
      case 'ẫ':
      case 'ậ':
      case 'ă':
      case 'ắ':
      case 'ằ':
      case 'ặ':
        return 'a';
      case 'ẽ':
      case 'è':
      case 'é':
      case 'ẻ':
      case 'ẹ':
      case 'ê':
      case 'ề':
      case 'ế':
      case 'ể':
      case 'ệ':
      case 'ễ':
        return 'e';
      case 'ì':
      case 'í':
      case 'ỉ':
      case 'ị':
      case 'ĩ':
        return 'i';
      case 'ó':
      case 'ỏ':
      case 'ò':
      case 'ọ':
      case 'õ':
      case 'ô':
      case 'ố':
      case 'ổ':
      case 'ồ':
      case 'ộ':
      case 'ỗ':
      case 'ơ':
      case 'ớ':
      case 'ờ':
      case 'ỡ':
        return 'o';
      case 'ù':
      case 'ú':
      case 'ụ':
      case 'ũ':
      case 'ư':
      case 'ừ':
      case 'ứ':
      case 'ữ':
      case 'ự':
        return 'u';
      case 'ỳ':
      case 'ý':
      case 'ỷ':
      case 'ỵ':
      case 'ỹ':
        return 'y';
      case 'đ':
        return 'd';
    }
    return char;
  });
};

export function formatAccountBankNumber(str: string) {
  return str?.replace(/\b[\dX][-. \dX]+(\d{4})\b/g, '**** **** **** $1');
}
