import Axios from '../services/axios';

const PATH = 'lender/pools';

export function listPools() {
  return Axios.get(`${PATH}`);
}

export function detailPools(id: string) {
  return Axios.get(`${PATH}/${id}`);
}

export function investPools(id: string, data: any) {
  return Axios.post(`${PATH}/${id}`, data);
}

export function historyTransactionPool(id: string) {
  return Axios.get(`${PATH}/${id}/transactions`);
}

export function withDrawPool(id: string, data: any) {
  return Axios.post(`${PATH}/${id}/withdraw`, data);
}

export function getContractInvest(id: string) {
  return Axios.get(`${PATH}/${id}/contract`);
}
