
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import constRouter from '@/constants/constRouter';
// import { getTransferResult } from '@/apis/ApiBank';
import {
  numberToMoney,
  formatAccountBankNumber,
  formatTime,
} from '@/utils/index';
import BaseModal from '@/components/Base/BaseModal.vue';
import IconPhone from '@/components/Icon/IconPhone.vue';
import { mapGetters } from 'vuex';
import { getContractInvest } from '@/apis/ApiPool';
import * as Analytics from '@/services/analytics';

export default defineComponent({
  components: {
    DefaultLayout,
    BaseButton,
    IconPhone,
    BaseModal,
  },
  data() {
    return {
      loading: false,
      numberToMoney,
      formatAccountBankNumber,
      formatTime,
      contractUrl: '',
      openModal: false,
    };
  },
  computed: {
    ...mapGetters({
      investmentInfo: 'getInvestmentInfo',
    }),
  },
  watch: {
    contractUrl(value) {
      if (value && this.loading) {
        this.loading = false;
        window.open(this.contractUrl, '_blank');
      }
    },
  },
  created() {
    if (this.investmentInfo.isSuccess === undefined) {
      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    } else {
      this.fetchContract();
    }
    // const params: any = this.$route.query.params;
    // if (params) {
    //   const obj = JSON.parse(atob(params));
    //   const pathname = window.location.pathname;
    //   window.history.pushState({}, document.title, pathname);
    //   this.getResultTransfer(obj.transactionId, obj.gateway);
    // }
  },
  methods: {
    handleClick() {
      Analytics.eventK2A('FINAL', 'DASHBOARD', 'Về trang chủ', '');
      this.$router.push({
        name: constRouter.DASHBOARD.name,
      });
    },
    async handleviewContract() {
      if (!this.contractUrl) {
        this.loading = true;
      } else {
        window.open(this.contractUrl, '_blank');
      }
      Analytics.eventK2A('FINAL', 'VIEW_ECONTRACT', 'Xem hợp đồng', '');
    },
    fetchContract() {
      getContractInvest(this.investmentInfo.id)
        .then((res) => {
          this.contractUrl = res.data.contract;
        })
        .catch((err) => {
          this.openModal = true;
          console.log(err);
        });
    },
    closeModal() {
      this.openModal = false;
    },
    onCopy(data: string) {
      navigator.clipboard.writeText(data);
    },
    // async getResultTransfer(transactionId: string, binCode: string) {
    //   this.loading = true;
    //   const res = await getTransferResult(transactionId, binCode);
    //   if (res.data.statusId === 3) {
    //     setTimeout(() => {
    //       this.getResultTransfer(transactionId, binCode);
    //     }, 3000);
    //   }
    //   if (res.data.statusId === 4 || res.data.statusId === 5) {
    //     this.data = res.data;
    //     this.loading = false;
    //     return;
    //   }
    // },
  },
});
